import { createGlobalStyle } from 'styled-components';
import { sizes } from './index';

const margins = [
  -20, -18, -16, -14, -12, -10, -8, -6, -4, -2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 25,
  26, 32, 38, 48, 64, 96, 128, 192, 256,
].map(
  (item) => `
  .m${item} { margin: ${item}px }
  
  .mt${item} {
    margin-top: ${item}px;
  }

  .mb${item} {
    margin-bottom: ${item}px;
  }

  .ml${item} {
    margin-left: ${item}px;
  }

  .mr${item} {
    margin-right: ${item}px;
  }`
);

const hidden = Object.keys(sizes).map(
  (item) => `
  .hidden-${item} {
    @media (max-width: ${sizes[item]}px) {
      display: none!important;
    }
  }
 
  .visible-${item} {
    @media (min-width: ${sizes[item] + 1}px) {
      display: none!important;
    }
  }
`
);

export default createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  ${margins}
  ${hidden}
  .center {
    text-align: center;
  }
  .w100 {
    width: 100%;
  }
  @media print {
    form {
      display: none;
    }
  }
`;
