import React, { CSSProperties, FunctionComponent } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

export interface IGalleryItem {
  index: number;
  caption: string;
  download: boolean;
  ext: string;
  imageIndex?: number;
  src: string;
}

interface IGallery {
  views: IGalleryItem[];
  viewerIsOpen: boolean;
  currentImage: number;
  closeGallery: () => void;
}

const modalStyles = {
  blanket: (base: CSSProperties) => ({ ...base, zIndex: 999999 }),
  positioner: (base: CSSProperties) => ({ ...base, zIndex: 1000000 }),
};

const Gallery: FunctionComponent<IGallery> = ({
  views,
  currentImage,
  viewerIsOpen,
  closeGallery,
}) => {
  return (
    <ModalGateway>
      {viewerIsOpen && (
        <Modal onClose={closeGallery} styles={modalStyles}>
          <Carousel currentIndex={currentImage} views={views} />
        </Modal>
      )}
    </ModalGateway>
  );
};

export default Gallery;
