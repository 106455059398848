import { TAttachment } from 'components/Attachments';
import { IOption } from 'components/Form/Select/SingleSelect';
import { getIn } from 'formik';
import UserAvatar from 'images/avatar-placeholder.png';
import { ALL_SELECTED_BACKEND_VALUE } from 'libs/constants';
import cloneDeep from 'lodash.clonedeep';
import { convertToFormData, fillEmpty } from 'libs/utils';
import { IThemeBackground } from 'theme';

export interface IMultiSelectOptions extends IOption {
  image?: string;
}

interface IRight {
  callback: (arg: any) => string;
  key: string;
}

const getRight = (item: any, right?: IRight | string) => {
  if (!right) {
    return null;
  }
  return typeof right === 'string' ? item[right] : right.callback(item[right.key]);
};

const getImage = (item: any, path?: string, prefix?: string) => {
  if (!path) {
    return null;
  }
  const currentPath = getIn(item, path);
  if (!currentPath) {
    return UserAvatar;
  }
  return prefix ? `${prefix}/${currentPath}` : currentPath;
};

export function selectSerializer<T>(
  data: Array<T & { [index: string]: any }>,
  config: { labelKey: string; valueKey: string } = {
    labelKey: 'name',
    valueKey: 'id',
  },
  firstItem?: IOption & T
): Array<IOption & T> {
  const result = data.map((item) => ({
    ...item,
    label: item[config.labelKey],
    value: item[config.valueKey],
  }));
  return firstItem ? [firstItem, ...result] : result;
}

export function filesSerializer<T extends { files: TAttachment[] }>(formValues: T): FormData {
  const values = {
    ...formValues,
    files: formValues.files.map((file) => (file instanceof File ? file : file.identifier)),
  };
  return convertToFormData(values, 'resource');
}

export function extendedFilesSerializer<T extends { files: TAttachment[] }>({
  existsFiles = [],
  toKey = 'files',
}: {
  existsFiles?: TAttachment[];
  toKey?: string;
}) {
  return function (formValues: T): FormData {
    const clonedFormValues = cloneDeep(formValues);
    const values = {
      ...clonedFormValues,
      [toKey]: [
        ...existsFiles
          ?.map((file) => {
            const id = file instanceof File ? 0 : file.id;
            return {
              id,
              file: '',
              _destroy: !clonedFormValues.files
                .map((clonedFile) => (clonedFile instanceof File ? undefined : clonedFile.id))
                .includes(id),
            };
          })
          .filter((file) => file._destroy),
        ...clonedFormValues.files
          .filter((file) => file instanceof File)
          .map((file) => ({
            title: file.name,
            file,
          })),
      ],
    };

    //@ts-ignore
    delete values.files;

    return convertToFormData(values, 'resource');
  };
}

export function amenitiesFilesSerializer<
  T extends { files: TAttachment[]; main_photo_index: string | number }
>({ existsFiles = [], toKey = 'files' }: { existsFiles?: TAttachment[]; toKey?: string }) {
  return function (formValues: T): FormData {
    const clonedFormValues = cloneDeep(formValues);
    const clonedFilesIds = clonedFormValues.files.map((clonedFile) => {
      return clonedFile instanceof File ? undefined : clonedFile.id;
    });

    const values = {
      ...clonedFormValues,
      [toKey]: [
        ...[
          ...existsFiles?.map((file) => {
            const id = file instanceof File ? 0 : file.id;
            return {
              id,
              file,
              is_main: id === formValues.main_photo_index,
              _destroy: !clonedFilesIds.includes(id),
            };
          }),
          ...clonedFormValues.files
            .filter((file) => file instanceof File)
            ?.map((file) => {
              return {
                file,
                is_main: file.name === formValues.main_photo_index,
              };
            }),
        ],
      ],
    };

    //@ts-ignore
    delete values.files;
    //@ts-ignore
    delete values.main_photo_index;
    delete values.amenity_main_photo_checkbox;

    return convertToFormData(values, 'resource');
  };
}

export function multiSelectSerializer<T>(
  data: Array<T & { [index: string]: any }>,
  config: {
    imagePath?: string;
    labelKeys: string[];
    right?: string | IRight;
    valueKey: string;
  } = {
    imagePath: undefined,
    labelKeys: ['name'],
    right: undefined,
    valueKey: 'id',
  }
): Array<IMultiSelectOptions & T> {
  return data.map((item) => ({
    ...item,
    image: getImage(item, config.imagePath, process.env.REACT_APP_IMAGE_PREFIX),
    label: config.labelKeys.map((label) => item[label]).join(' '),
    right: getRight(item, config.right),
    value: item[config.valueKey],
  }));
}

export function fillEmptySerializer<T>(initialValues: T) {
  return (data: { resource: T }) => fillEmpty(data.resource, initialValues);
}

export function advancedMultiSelectSerializer<T, P extends { id: number }>(
  values: T,
  key: keyof typeof values,
  withFreeze = true
) {
  const optionsLength = getIn(values, ['meta', `${key.toString()}_options_length`], 0);
  const data: P[] = getIn(values, [key.toString()], []);

  if (!withFreeze && optionsLength && optionsLength === data.length) {
    return ALL_SELECTED_BACKEND_VALUE; // "all"
  }
  return data.map((item) => item.id);
}

export const websiteSerialize = (values: IThemeBackground) =>
  convertToFormData(
    {
      image: values.image[0] || null,
    },
    'resource'
  );
