import { DEFAULT_TIMEZONE, PROJECT_TITLE } from 'libs/constants';
import cookies from 'libs/cookie';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import {
  commonActions,
  doneRedirectAfterSignInAction,
  logOutAction,
  needRedirectAfterSignInAction,
  resetPasswordActions,
  setAccountAction,
  setCompanyAction,
  setProfileAction,
  setUserAction,
  signInActions,
} from './actions';
import { ICommon, TEnvAction, TEnvState } from './types';

const initialCommonObject: ICommon = {
  account: {
    auth_filled: true,
    discarded_at: null,
    guid: '',
    id: 0,
    is_suspended: false,
    is_annual_subscription: false,
    is_spent_time_in_tasks_required: false,
    is_hide_pets: false,
    is_hide_vehicles: false,
    is_hide_attach_title: false,
    has_bookings_buffer: false,
    name: '',
    notifications_phone_number: null,
    subdomain: '',
    time_zone: DEFAULT_TIMEZONE,
    invoice_emails_attributes: [],
    task_due_days: 0,
    plan: {
      id: 0,
      price: 0,
      title: '',
    },
  },
  company: {
    discarded_at: null,
    id: 0,
    name: '',
    subdomain: '',
    time_zone: DEFAULT_TIMEZONE,
    is_location_confirmed: true,
  },
  is_loaded: false,
  main: {
    name: PROJECT_TITLE,
    subdomain: '',
  },
  profile: {
    additional_permissions: {},
    approved: false,
    auth_filled: true,
    discarded_at: null,
    id: 0,
    is_admin: false,
    permissions: {},
    details_buildings: [],
    phone_number: '',
    cell_num: '',
    email: '',
    has_units: false,
  },
  type: undefined,
};

function getInitialState() {
  const auth = cookies.get('auth');
  const { tokens, user } = auth || { tokens: {}, user: {} };
  const isAuthorized = Boolean(auth);
  return Immutable({
    common: initialCommonObject,
    error: false,
    errorMessage: '',
    isAuthorized,
    loading: false,
    needRedirectAfterSignIn: false,
    tokens,
    user,
  });
}

const initialState: TEnvState = getInitialState();

export const reducer = (state: TEnvState = initialState, action: TEnvAction) => {
  switch (action.type) {
    case getType(resetPasswordActions.request):
      return state.merge(
        {
          error: false,
          loading: true,
        },
        { deep: true }
      );
    case getType(signInActions.request):
    case getType(commonActions.request):
      return state.merge(
        {
          common: {
            is_loaded: false,
          },
          error: false,
          errorMessage: '',
          loading: true,
        },
        { deep: true }
      );

    case getType(resetPasswordActions.failure):
      return state.merge(
        {
          error: true,
          loading: false,
        },
        { deep: true }
      );
    case getType(signInActions.failure):
      return state.merge(
        {
          common: {
            is_loaded: false,
          },
          error: true,
          errorMessage:
            //@ts-ignore
            action.payload.response.data.errors?.join(',') || action.payload.response.data.error,
          loading: false,
        },
        { deep: true }
      );
    case getType(commonActions.failure):
      return state.merge(
        {
          common: {
            is_loaded: false,
          },
          error: true,
          loading: false,
        },
        { deep: true }
      );

    case getType(logOutAction):
      return state.merge({
        common: initialCommonObject,
        error: false,
        isAuthorized: false,
        loading: false,
        tokens: {},
        user: {},
      });

    case getType(needRedirectAfterSignInAction):
      return state.merge({
        needRedirectAfterSignIn: true,
      });

    case getType(doneRedirectAfterSignInAction):
      return state.merge({
        needRedirectAfterSignIn: false,
      });

    case getType(signInActions.success):
      return state.merge({
        isAuthorized: true,
        loading: false,
        tokens: action.payload.tokens,
        user: action.payload.user,
        common: initialCommonObject,
      });

    case getType(commonActions.success):
      return state.merge({
        common: {
          ...action.payload.common,
          account: {
            ...action.payload.common.account,
          },
          company: {
            ...action.payload.common.company,
          },
          is_loaded: true,
          profile: {
            ...action.payload.common.profile,
          },
        },
        error: false,
        loading: false,
      });

    case getType(resetPasswordActions.success):
      return state.merge({
        isAuthorized: false,
        tokens: {},
        user: action.payload.user,
      });

    /**
     * SET ACCOUNT
     */
    case getType(setAccountAction):
      return state.merge(
        {
          common: {
            account: action.payload.account,
          },
        },
        { deep: true }
      );

    /**
     * SET COMPANY
     */
    case getType(setCompanyAction):
      return state.merge(
        {
          common: {
            company: action.payload.company,
          },
        },
        { deep: true }
      );

    /**
     * SET PROFILE
     */
    case getType(setProfileAction):
      return state.merge(
        {
          common: {
            profile: action.payload.profile,
          },
        },
        { deep: true }
      );

    /**
     * SET USER
     */
    case getType(setUserAction):
      return state.merge({
        user: action.payload.user,
      });

    default:
      return state;
  }
};
