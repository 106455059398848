import React, { lazy } from 'react';

// icons
import { IRoutes } from 'hooks/useRoutes';
import { ReactComponent as AdministrationIcon } from 'icons/menu/administration.svg';
import { ReactComponent as AmenitiesIcon } from 'icons/menu/amenities.svg';
import { ReactComponent as CalendarIcon } from 'icons/menu/calendar.svg';
import { ReactComponent as ClassifiedIcon } from 'icons/menu/classfield.svg';
import { ReactComponent as DashboardIcon } from 'icons/menu/dashboard.svg';
import { ReactComponent as DocumentsIcon } from 'icons/menu/documents.svg';
import { ReactComponent as MessageForumIcon } from 'icons/menu/message-forum.svg';
import { ReactComponent as NewsIcon } from 'icons/menu/news.svg';
import { ReactComponent as RequestIcon } from 'icons/menu/request.svg';
import { ReactComponent as TasksIcon } from 'icons/menu/tasks.svg';
import { ReactComponent as UnitMessagesIcon } from 'icons/menu/unit-messages.svg';
import { ReactComponent as UnitsIcon } from 'icons/menu/units.svg';
import { URL_TO_REQUESTS_LIST, URL_TO_TASKS_LIST, TUTORIALS_PATHS } from 'libs/constants';

const account: IRoutes[] = [
  {
    link: {
      dataTest: 'dashboard',
      icon: <DashboardIcon />,
      label: 'Dashboard',
      path: '/dashboard',
    },
    permission: 'dashboard',
    routes: [
      {
        component: lazy(() => import('pages/Dashboard')),
        path: '/dashboard',
        type: 'read',
        ...TUTORIALS_PATHS.dashboard,
      },
    ],
  },
  {
    permission: 'buildings',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Buildings/Create')),
        path: '/administration/buildings/create',
        type: 'create',
        ...TUTORIALS_PATHS.buildings,
      },
      {
        component: lazy(() => import('pages/Administration/Buildings/Edit')),
        path: '/administration/buildings/:id/edit',
        type: 'edit',
        ...TUTORIALS_PATHS.buildings,
      },
      {
        component: lazy(() => import('pages/Administration/Buildings/List')),
        path: '/administration/buildings/',
        type: 'read',
        ...TUTORIALS_PATHS.buildings,
      },
    ],
  },
  {
    permission: 'users_accounts',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Users/Create')),
        path: '/administration/users/create',
        title: 'Users',
        type: 'create',
        ...TUTORIALS_PATHS.users,
      },
      {
        component: lazy(() => import('pages/Administration/Users/Invite')),
        path: '/administration/users/invite',
        title: 'Invite Users',
        type: 'read',
        ...TUTORIALS_PATHS.users,
      },
      {
        component: lazy(() => import('pages/Administration/Users/Invitations')),
        path: '/administration/users/invitations',
        title: 'Invite Users',
        type: 'read',
        ...TUTORIALS_PATHS.users,
      },
      {
        component: lazy(() => import('pages/Administration/Users/List')),
        path: '/administration/users',
        title: 'Users',
        type: 'read',
        ...TUTORIALS_PATHS.users,
      },
    ],
  },
  {
    permission: 'reports',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Reports/UserReport')),
        path: '/administration/reports/user',
        title: 'User Reports',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Administration/Reports/AnnouncementReports')),
        path: '/administration/reports/announcement',
        title: 'Announcement Reports',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Administration/Reports/TaskReport')),
        path: '/administration/reports/task',
        title: 'Task Reports',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Administration/Reports/WelcomeLetter')),
        path: '/administration/welcome_letter',
        title: 'Welcome Letter',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Administration/Reports')),
        path: '/administration/reports',
        title: 'Reports',
        type: 'read',
      },
    ],
  },
  {
    permission: 'task_categories',
    routes: [
      {
        component: lazy(() => import('pages/Administration/TaskCategories/Create')),
        path: '/administration/task/categories/create',
        title: 'Create Task Category',
        type: 'create',
        ...TUTORIALS_PATHS.taskCategories,
      },
      {
        component: lazy(() => import('pages/Administration/TaskCategories/Edit')),
        path: '/administration/task/categories/:id/edit',
        title: 'Edit Task Category',
        type: 'edit',
        ...TUTORIALS_PATHS.taskCategories,
      },
      {
        component: lazy(() => import('pages/Administration/TaskCategories/List')),
        path: '/administration/task/categories',
        title: 'Task Categories',
        type: 'read',
        ...TUTORIALS_PATHS.taskCategories,
      },
    ],
  },
  {
    permission: 'projects',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Projects/Create')),
        path: '/administration/projects/create',
        title: 'Create Project',
        type: 'create',
        ...TUTORIALS_PATHS.projects,
      },
      {
        component: lazy(() => import('pages/Administration/Projects/Edit')),
        path: '/administration/projects/:id/edit',
        title: 'Edit Project',
        type: 'edit',
        ...TUTORIALS_PATHS.projects,
      },
      {
        component: lazy(() => import('pages/Administration/Projects/Specific')),
        path: '/administration/projects/:id',
        title: 'Project',
        type: 'read',
        ...TUTORIALS_PATHS.projects,
      },
      {
        component: lazy(() => import('pages/Administration/Projects/List')),
        path: '/administration/projects',
        title: 'Projects',
        type: 'read',
        ...TUTORIALS_PATHS.projects,
      },
    ],
  },
  {
    permission: 'website_maintenance',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Website/Create')),
        path: '/administration/website/pages/create',
        title: 'Create Website Page',
        type: 'create',
        ...TUTORIALS_PATHS.website,
      },
      {
        component: lazy(() => import('pages/Administration/Website/Edit')),
        path: '/administration/website/pages/:id/edit',
        title: 'Edit Website Page',
        type: 'edit',
        ...TUTORIALS_PATHS.website,
      },
      {
        component: lazy(() => import('pages/Administration/Website/List')),
        path: '/administration/website/pages',
        title: 'Website Pages',
        type: 'read',
        ...TUTORIALS_PATHS.website,
      },
    ],
  },
  {
    permission: 'user_groups',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Groups/Create')),
        path: '/administration/groups/create',
        title: 'Create Group',
        type: 'create',
        ...TUTORIALS_PATHS.groups,
      },
      {
        component: lazy(() => import('pages/Administration/Groups/Edit')),
        path: '/administration/groups/:id/edit',
        title: 'Edit Group',
        type: 'edit',
        ...TUTORIALS_PATHS.groups,
      },
      {
        component: lazy(() => import('pages/Administration/Groups/Specific')),
        path: '/administration/groups/:id',
        title: 'Group',
        type: 'read',
        ...TUTORIALS_PATHS.groups,
      },
      {
        component: lazy(() => import('pages/Administration/Groups/List')),
        path: '/administration/groups',
        title: 'Groups',
        type: 'read',
        ...TUTORIALS_PATHS.groups,
      },
    ],
  },
  {
    permission: 'invoices',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Invoices')),
        path: '/administration/invoices',
        title: 'Invoices',
        type: 'read',
      },
    ],
  },
  {
    permission: 'plan_and_billing',
    routes: [
      {
        component: lazy(() => import('pages/Administration/PlanAndBilling')),
        path: '/administration/plan',
        title: 'Plan and Billing',
        type: 'read',
      },
    ],
  },
  {
    permission: 'notices',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Notices')),
        path: '/administration/notices',
        title: 'Notices',
        type: 'read',
        ...TUTORIALS_PATHS.notices,
      },
    ],
  },
  {
    permission: 'global_settings',
    routes: [
      {
        component: lazy(() => import('pages/Administration/GlobalSettings')),
        path: '/administration/global_settings',
        title: 'Global Settings',
        type: 'read',
        ...TUTORIALS_PATHS.globalSettings,
      },
    ],
  },
  {
    permission: 'event_log',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Log')),
        path: '/administration/log',
        title: 'Log',
        type: 'read',
      },
    ],
  },
  {
    permission: 'accounts',
    routes: [
      {
        component: lazy(() => import('pages/Administration/AccountSettings')),
        path: '/administration/settings',
        title: 'Account Settings',
        type: 'edit',
      },
    ],
  },
  {
    link: {
      dataTest: 'administration',
      icon: <AdministrationIcon />,
      label: 'Administration',
      path: '/administration',
    },
    permission: 'administration',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Main')),
        exact: true,
        path: '/administration',
        title: 'Administration',
        type: 'read',
      },
    ],
  },
  {
    link: {
      dataTest: 'news',
      icon: <NewsIcon />,
      label: 'News',
      path: '/news?status=active',
    },
    permission: 'news_announcements',
    routes: [
      {
        component: lazy(() => import('pages/News/Create')),
        path: '/news/create/*',
        title: 'Create Announcement',
        type: 'create',
        ...TUTORIALS_PATHS.news,
      },
      {
        component: lazy(() => import('pages/News/Edit')),
        path: '/news/:id/edit',
        title: 'Edit Announcement',
        type: 'edit',
        ...TUTORIALS_PATHS.news,
      },
      {
        component: lazy(() => import('pages/News/Specific')),
        path: '/news/:id',
        title: 'Announcement',
        type: 'read',
        ...TUTORIALS_PATHS.news,
      },
      {
        component: lazy(() => import('pages/News/List')),
        path: '/news',
        title: 'Announcements',
        type: 'read',
        ...TUTORIALS_PATHS.news,
      },
    ],
  },
  {
    link: {
      dataTest: 'documents',
      icon: <DocumentsIcon />,
      label: 'Documents',
      path: '/documents?archive=active',
    },
    permission: 'documents',
    routes: [
      {
        component: lazy(() => import('pages/Documents/List')),
        path: '/documents/*',
        title: 'Documents',
        type: 'read',
        ...TUTORIALS_PATHS.documents,
      },
    ],
  },
  {
    link: {
      dataTest: 'classifieds',
      icon: <ClassifiedIcon />,
      label: 'Classifieds',
      path: '/classifieds',
    },
    permission: 'classifieds',
    routes: [
      {
        component: lazy(() => import('pages/Classifieds/Create')),
        path: '/classifieds/create',
        title: 'Create Classified',
        type: 'create',
        ...TUTORIALS_PATHS.classifieds,
      },
      {
        component: lazy(() => import('pages/Classifieds/Edit')),
        path: '/classifieds/:id/edit',
        title: 'Edit Classified',
        type: 'edit',
        ...TUTORIALS_PATHS.classifieds,
      },
      {
        component: lazy(() => import('pages/Classifieds/Specific')),
        path: '/classifieds/:id',
        title: 'Classified',
        type: 'read',
        ...TUTORIALS_PATHS.classifieds,
      },
      {
        component: lazy(() => import('pages/Classifieds/List')),
        path: '/classifieds',
        title: 'Classifieds',
        type: 'read',
        ...TUTORIALS_PATHS.classifieds,
      },
    ],
  },
  {
    link: {
      dataTest: 'forum-messages',
      icon: <MessageForumIcon />,
      label: 'Message Forum',
      path: '/forum/messages?archive=active',
    },
    permission: 'message_forum',
    routes: [
      {
        component: lazy(() => import('pages/MessageForum/Create')),
        path: '/forum/messages/create',
        title: 'Create Message Forum',
        type: 'create',
        ...TUTORIALS_PATHS.messageForum,
      },
      {
        component: lazy(() => import('pages/MessageForum/Edit')),
        path: '/forum/messages/:id/edit',
        title: 'Edit Message Forum',
        type: 'edit',
        ...TUTORIALS_PATHS.messageForum,
      },
      {
        component: lazy(() => import('pages/MessageForum/Specific')),
        path: '/forum/messages/:id',
        title: 'Message Forum',
        type: 'read',
        ...TUTORIALS_PATHS.messageForum,
      },
      {
        component: lazy(() => import('pages/MessageForum/List')),
        path: '/forum/messages',
        title: 'Message Forum',
        type: 'read',
        ...TUTORIALS_PATHS.messageForum,
      },
    ],
  },
  {
    permission: 'request_management',
    routes: [
      {
        component: lazy(() => import('pages/Requests/Edit')),
        path: '/requests/:id/edit',
        title: 'Edit Request',
        type: 'edit',
      },
    ],
  },
  {
    link: {
      dataTest: 'requests',
      icon: <RequestIcon />,
      label: 'Requests',
      path: URL_TO_REQUESTS_LIST,
    },
    permission: 'requests',
    routes: [
      {
        component: lazy(() => import('pages/Requests/Create')),
        path: '/requests/create',
        title: 'Create Request',
        type: 'create',
        ...TUTORIALS_PATHS.requests,
      },
      {
        component: lazy(() => import('pages/Requests/Specific')),
        path: '/requests/:id',
        title: 'Request',
        type: 'read',
        ...TUTORIALS_PATHS.requests,
      },
      {
        component: lazy(() => import('pages/Requests/List')),
        path: '/requests',
        title: 'Requests',
        type: 'read',
        ...TUTORIALS_PATHS.requests,
      },
    ],
  },
  {
    link: {
      dataTest: 'tasks',
      icon: <TasksIcon />,
      label: 'Tasks',
      path: URL_TO_TASKS_LIST,
    },
    permission: 'tasks',
    routes: [
      {
        component: lazy(() => import('pages/Tasks/Create')),
        path: '/tasks/create',
        title: 'Create Task',
        type: 'create',
        ...TUTORIALS_PATHS.tasks,
      },
      {
        component: lazy(() => import('pages/Tasks/Edit')),
        path: '/tasks/:id/edit',
        title: 'Task',
        type: 'edit',
        ...TUTORIALS_PATHS.tasks,
      },
      {
        component: lazy(() => import('pages/Tasks/Specific')),
        path: '/tasks/:id',
        title: 'Task',
        type: 'read',
        ...TUTORIALS_PATHS.tasks,
      },
      {
        component: lazy(() => import('pages/Tasks/List')),
        path: '/tasks',
        title: 'Tasks',
        type: 'read',
        ...TUTORIALS_PATHS.tasks,
      },
    ],
  },
  {
    permission: 'amenity_bookings',
    routes: [
      {
        component: lazy(() => import('pages/AmenityBookings/Approve')),
        path: '/amenities/bookings/:id/approve',
        title: 'Approve Amenity',
        type: 'edit',
        ...TUTORIALS_PATHS.amenityBookings,
      },
      {
        component: lazy(() => import('pages/AmenityBookings/Edit')),
        path: '/amenities/bookings/:id/edit',
        title: 'Edit Amenity Booking',
        type: 'read',
        ...TUTORIALS_PATHS.amenityBookings,
      },
      {
        component: lazy(() => import('pages/AmenityBookings/Create')),
        path: '/amenities/:id/bookings/create',
        title: 'Create Amenity Booking',
        type: 'create',
        ...TUTORIALS_PATHS.amenityBookings,
      },
      {
        component: lazy(() => import('pages/AmenityBookings/Specific')),
        path: '/amenities/bookings/:id',
        title: 'Amenity Booking',
        type: 'read',
        ...TUTORIALS_PATHS.amenityBookings,
      },
      {
        component: lazy(() => import('pages/AmenityBookings/List')),
        path: '/amenities/bookings',
        title: 'Amenity Bookings',
        type: 'read',
        ...TUTORIALS_PATHS.amenityBookings,
      },
    ],
  },
  {
    link: {
      dataTest: 'amenities',
      icon: <AmenitiesIcon />,
      label: 'Amenities',
      path: '/amenities?status=active',
    },
    permission: 'amenities',
    routes: [
      {
        component: lazy(() => import('pages/Amenities/Create')),
        path: '/amenities/create/*',
        title: 'Create Amenity',
        type: 'create',
        ...TUTORIALS_PATHS.amenities,
      },
      {
        component: lazy(() => import('pages/Amenities/Edit')),
        path: '/amenities/:id/edit',
        title: 'Amenity',
        type: 'edit',
        ...TUTORIALS_PATHS.amenities,
      },
      {
        component: lazy(() => import('pages/Amenities/List')),
        path: '/amenities',
        title: 'Amenities',
        type: 'read',
        ...TUTORIALS_PATHS.amenities,
      },
    ],
  },
  {
    link: {
      dataTest: 'events',
      icon: <CalendarIcon />,
      label: 'Calendar',
      path: '/events',
    },
    permission: 'calendar_events',
    routes: [
      {
        type: 'read',
        path: '/events/*',
        component: lazy(() => import('pages/Calendar')),
        ...TUTORIALS_PATHS.calendar,
      },
    ],
  },
  {
    link: {
      dataTest: 'unit-messages',
      icon: <UnitMessagesIcon />,
      label: 'Unit Messages',
      path: '/unit/messages',
    },
    permission: 'unit_messages',
    routes: [
      {
        component: lazy(() => import('pages/UnitMessages/Create')),
        path: '/units/:id/messages/create',
        title: 'Create Unit Message',
        type: 'create',
        ...TUTORIALS_PATHS.unitMessages,
      },
      {
        component: lazy(() => import('pages/UnitMessages/Specific')),
        path: '/unit/messages/:id',
        title: 'Unit Message',
        type: 'read',
        ...TUTORIALS_PATHS.unitMessages,
      },
      {
        component: lazy(() => import('pages/UnitMessages/List')),
        path: '/unit/messages',
        title: 'Unit Messages',
        type: 'read',
        ...TUTORIALS_PATHS.unitMessages,
      },
    ],
  },
  {
    link: {
      dataTest: 'units',
      icon: <UnitsIcon />,
      label: 'Units',
      path: '/units',
    },
    permission: 'units',
    routes: [
      {
        component: lazy(() => import('pages/Units/Create')),
        path: '/units/create',
        title: 'Create Unit',
        type: 'create',
        ...TUTORIALS_PATHS.units,
      },
      {
        component: lazy(() => import('pages/Units/Edit')),
        path: '/units/:id/edit',
        title: 'Edit Unit',
        type: 'edit',
        ...TUTORIALS_PATHS.units,
      },
      {
        component: lazy(() => import('pages/Units/Specific')),
        path: '/units/:id/*',
        title: 'Unit',
        type: 'read',
        ...TUTORIALS_PATHS.units,
      },
      {
        component: lazy(() => import('pages/Units/List')),
        path: '/units',
        title: 'Units',
        type: 'read',
        ...TUTORIALS_PATHS.units,
      },
    ],
  },
  {
    routes: [
      {
        component: lazy(() => import('pages/Profile')),
        path: '/profile/:id',
        title: 'Profile',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Profile')),
        path: '/profile',
        title: 'Profile',
        type: 'read',
      },
    ],
  },
];

export default account;
