import { AxiosResponse } from 'axios';
import axios, { Canceler } from 'axios';
import { TApi } from 'libs/api';
import { makeSearchParamsObject } from 'libs/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import usePagination, {
  initialMeta,
  IPaginationMeta,
  IResponseMeta,
  LoadResourcesParams,
} from './usePagination';
import useHandleHttpError from './useHandleHttpError';

interface IState<T> {
  resources: T[];
}

interface IFetchResources<T> {
  setter?: (resources: T[]) => (state: { resources: T[] }) => { resources: T[] };
}

interface IUsePaginatedResources<T> {
  resources: T[];
  loadMore: () => void;
  fetchData: () => void;
  hasMore: boolean;
  paginationMeta: IPaginationMeta;
}

interface IUsePaginatedResourcesProps {
  api: TApi;
  search: URLSearchParams;
  locationSearch: string;
  initialLoad?: boolean;
  onLoadedMore?: (meta: IPaginationMeta) => void;
  initialMetaValues?: IPaginationMeta;
}

export default function usePaginatedResources<T>({
  api,
  search,
  locationSearch,
  onLoadedMore,
  initialLoad = true,
  initialMetaValues = initialMeta,
}: IUsePaginatedResourcesProps): IUsePaginatedResources<T> {
  const handleHttpError = useHandleHttpError();
  const cancel = useRef<Canceler | null>(null);
  const [{ resources }, setResources] = useState<IState<T>>({ resources: [] });
  const loadResources = useCallback(
    async ({
      params = {},
      onSuccess,
      meta,
      setter = (responseData) => (state) => ({ resources: [...state.resources, ...responseData] }),
    }: LoadResourcesParams & IFetchResources<T>) => {
      try {
        const response: AxiosResponse<{
          resources: T[];
          meta: IResponseMeta;
        }> = await api({
          // Can't import CancelToken as
          // import { CancelToken } from 'axios';
          // Because in this case CancelToken is interface but need class.
          cancelToken: new axios.CancelToken((cancelHandler) => {
            cancel.current = cancelHandler;
          }),
          params: {
            ...params,
            ...meta,
          },
        });
        onSuccess && onSuccess(response.data.meta);
        setResources(setter(response.data.resources));
      } catch (error) {
        handleHttpError(error);
      }
    },
    []
  );

  const {
    loadMore,
    hasMore,
    onSuccess: onSuccessLoaded,
    meta: paginationMeta,
  } = usePagination({
    loadResources,
    initialMetaValues,
    onLoadedMore,
  });

  const fetchData = useCallback(() => {
    loadResources({
      meta: { ...initialMeta, per_page: paginationMeta.page * paginationMeta.per_page },
      onSuccess: onSuccessLoaded,
      params: makeSearchParamsObject(search),
      setter: (data) => () => ({ resources: data }),
    });
  }, [
    makeSearchParamsObject,
    initialMeta,
    paginationMeta.page,
    paginationMeta.per_page,
    onSuccessLoaded,
    loadResources,
  ]);

  useEffect(() => {
    if (initialLoad) {
      fetchData();
    }
  }, [locationSearch, initialLoad]);

  useEffect(() => {
    return () => {
      if (cancel.current !== null) {
        cancel.current();
      }
    };
  }, [cancel]);

  return {
    fetchData,
    hasMore,
    loadMore: () => loadMore(makeSearchParamsObject(search)),
    resources,
    paginationMeta,
  };
}
